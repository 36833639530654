/* Copyright 2022- Martin Kufner */
Object.defineProperties(Node.prototype, {
    ancestors: {
        value(closest, include = true) {
            if(typeof closest === "string") closest = this.closest(closest);
            const fn = include ?
                function* (e) {do { yield e; } while(e != closest && (e = e.parentNode))} :
                function* (e) {do { yield e; } while((e = e.parentNode) && e != closest)};
            return [...fn(this)];
        }
    },
    getTextNodes: {
        get() {
            const nodes = [],
                iterator = document.createNodeIterator(this, NodeFilter.SHOW_TEXT);
            let currentNode;
            while((currentNode = iterator.nextNode())) nodes.push(currentNode);
            return nodes;
        }
    },
    getElementsByNodeNameStarting: {
        value(name) {
            const nodes = [],
                iterator = document.createNodeIterator(this, NodeFilter.SHOW_ELEMENT,
                    (node) =>
                        node.nodeName.startsWith(name)
                            ? NodeFilter.FILTER_ACCEPT
                            : NodeFilter.FILTER_REJECT
                );
            let currentNode;
            while((currentNode = iterator.nextNode())) nodes.push(currentNode);
            return nodes;
        }
    }
});

