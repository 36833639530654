/* Copyright 2022- Martin Kufner */
if(typeof document !== 'undefined') {
    Object.defineProperties(document, {
        metas: {
            get() {
                const rv = {};
                for(const meta of this.getElementsByTagName('meta')) {
                    const [name, ...parts] = meta.name.replace(/\]/g, '').split(/\[/);
                    if(!parts.length) rv[name] = meta.content;
                    else {
                        const content = {},
                            last = parts.pop();
                        parts.reduce((p, c) => p[c] = {}, content)[last] = meta.content;
                        rv[name] = content;
                    }
                }
                return rv;
            }
        },
        meta: {
            value: function (name, content, create=true) {
                let node = this.head.querySelector(`meta[name="${name}"]`);
                if(arguments.length === 1) return node?.content;
                if(content === undefined) return node?.remove();
                if(!node && create) {
                    node = this.createElement('meta');
                    node.name = name;
                    this.head.append(node);
                }
                return node && (node.content = content);
            }
        },
        clearClass: {
            value(klass) {
                for(const n of this.getElementsByClassName(klass)) n.classList.remove(klass);
            }
        },
        removeElementsByClassName: {
            value(klass) {
                for(const n of this.getElementsByClassName(klass)) n.remove(klass);
            }
        },
        getMatchingCSSRules: {
            value(regexp) {
                Array.from(this.styleSheets).flatMap(s => Array.from(s.cssRules)).filter(r => regexp.test(r.selectorText)).map(r => r.cssText);
            }
        },
        setClass: {
            value(isSet, ...classList) {
                document.documentElement.classList[isSet ? "add" : "remove"](...classList);
            }
        }
    });
}

