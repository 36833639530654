/* Copyright 2022- Martin Kufner */
import {ContentTag, objecttype} from "./content-tag.js";

Object.defineProperties(HTMLSelectElement.prototype, {
    values: {
        get() { return [...this.selectedOptions].map(n => n.value) },
        set(values) {
            if(values instanceof Array) this.multiple = true;
            values = [values].flat().filter(v => v);
            [...this.options].forEach(n => n.selected = values.includes(n.value));
        }
    }
});


// static create = (() => {
//     const cT = function (node, ...args) { return new ContentTag(node, ...args).node }
//     Object.defineProperties(cT, {
//         Fragment: {
//             value: (...args) => this.Fragment(...args)
//         },
//
//     });
//     return cT;
// })();
const tagNames = "a abbr acronym address applet area article aside audio b base basefont bdi bdo big blockquote body br button canvas caption center cite code col colgroup data datalist dd del details dfn dialog dir div dl dt em embed fieldset figcaption figure font footer form frame frameset head header hgroup h1 hr html i iframe img input ins kbd keygen label legend li link main map mark menu menuitem meta meter nav noframes noscript object ol optgroup option output p param picture pre progress q rp rt ruby s samp script section select small source span strike strong style sub summary sup svg table tbody td template textarea tfoot th thead time title tr track tt u ul var video wbr".split(/\s+/).filter(t => t);

export const cT = function (node, ...args) { return new ContentTag(node, ...args).node };

Object.defineProperties(cT, {
    ...Object.fromEntries(tagNames.map(tag => [tag.toUpperCase(), {value: cT.bind(null, tag)}])),
    Fragment: {value(...args) { return ContentTag.Fragment(...args) }},
    update: {value(...args) {return ContentTag.update(...args)}},
    eventTarget: {
        value(target, node) {
            if(!node) node = target;
            const nodes = [...node.querySelectorAll("[events]")];
            if(node.matches && node.matches("[events]")) nodes.unshift(node);
            nodes.forEach(node => new ContentTag(node).eventTarget = target);
            return node;
        }
    },
    Slot: {value(slot, ...args) { return this.SPAN({slot}, ...args) }},
    LinkTo: {value(text, href, ...options) { return this.A(text, {href: href}, ...options) }},
    Input: {value(...args) { return this.INPUT({autocomplete: "off"}, ...args) }},
    TextField: {
        value(name, value, ...options) {
            return this.INPUT({
                type: 'text',
                value: value,
                name: name
            }, {autocomplete: "off"}, ...options);

        }
    },
    CheckRadio: {
        value(type, name, value, checked, option, ...options) {
            if(option && objecttype(option) !== 'Object') {
                options.unshift(option);
                option = undefined;
            }
            if(typeof checked !== 'boolean') checked = value === checked;
            const input = this('input', {type, value, name, checked, autocomplete: "off"}, option);
            if(options.length === 0) return input;
            const label = this.LABEL(input, ...options);
            if(input.id) label.htmlFor = input.id;
            return label;
        }
    },

    CheckBox: {value(...options) { return this.CheckRadio('checkbox', ...options)}},
    RadioButton: {value(...options) { return this.CheckRadio('radio', ...options)}},
    HiddenField: {
        value(name, value, ...options) {
            return this('input', {
                type: 'hidden',
                value: value,
                name: name
            }, ...options)
        }
    },
    OptGroup: {
        value(option, ...options) {
            if(typeof option === "string") option = {label: option};
            return this.OPTGROUP(option, this.Options(...options));
        }
    },
    Options: {
        value(...options) {
            const node = new DocumentFragment();
            options.flat().forEach(option => {
                switch(objecttype(option)) {
                    case "HTMLOptionElement":
                    case "HTMLOptGroupElement":
                    case "HTMLOptionsCollection":
                        break;
                    case "Object":
                    case "String":
                        option = this.OPTION(option);
                        break;
                    default:
                        return;
                }
                node.append(option);
            });
            return node;
        }
    },
    Select: {
        value(name, option, ...options) {
            let values;
            switch(objecttype(option)) {
                case 'Object':
                    values = option.value || option.values;
                    delete option.value;
                    delete option.values;
                    if("prompt" in option) {
                        if(option.prompt)
                            options.unshift({
                                value: "",
                                selected: values === undefined,
                                textContent: typeof option.prompt === "string" ? option.prompt : ""
                            });
                        delete option.prompt;
                    }
                    break;
                case 'Array':
                    values = option;
                    option = undefined;
                    break;
                default:
                    options.unshift(option);
                    option = undefined;
            }
            const node = this.SELECT({name}, option, {autocomplete: "off", values}, this.Options(...options));

            // node.append();
            // node.values = values;
            return node;
        }


    },
    SendForm: {
        value(action, method, params) {
            const ce = (tagName, options) => Object.assign(document.createElement(tagName), options);
            const form = ce("form", {action, method});
            form.append(...Object.entries(params).map(([name, value]) => ce("input", {type: "hidden", name, value})));
            document.body.append(form);
            form.submit();
            form.remove();
        }
    },

    _: {
        value(text, ...options) {
            const node = cT("qb-gettext");
            node.content = text;
            return node;
        }
    },
    ...ContentTag.utilities
});
