/* Copyright 2022- Martin Kufner */
Object.defineProperties(Date.prototype, {
    getDifference: {
        value(date) { return new DateDifference(this, date); }
    },
    ago: {
        value(num, prop) {
            switch(prop.toLowerCase()) {
                case "year":
                    prop = "FullYear";
                    break;
                case "day":
                    prop = "Date";
                    break;
                case "month":
                case "date":
                case "hour":
                case "minute":
                case "second":
                    prop = prop[0].toUpperCase() + prop.substring(1).toLowerCase();
                    break;
                default:
                    return this;
            }
            const date = new Date(this);
            date[`set${prop}`](date[`get${prop}`]() - num);
            return date;
        }
    }
});



class DateDifference {
    convert(date) {
        if (typeof date === "number") date = Date.parse(date * 1000);
        else if (typeof date === "string") date = new Date(date);
        if (date && date instanceof Date) return date;
        return new Date();
    }

    constructor(current, date) {
        current = this.convert(current);
        date = this.convert(date);
        const value = current - date,
            delta = Math.abs(value),
            origin = Date.parse(delta);
        this.past = Math.sign(value) > 0;
        this.all_days = Math.floor(delta / 86400000);
        this.msecs = origin.getUTCMilliseconds();
        this.seconds = origin.getUTCSeconds();
        this.minutes = origin.getUTCMinutes();
        this.hours = origin.getUTCHours();
        this.future = value > 0;
        this.years = origin.getUTCFullYear() - 1970;
        this.months = origin.getUTCMonth();
        this.days = origin.getUTCDate() - 1;
        this.all_seconds = value / 1000;
    }

    toString(nsecs=false) {
        const string = Object.entries({
            D: this.all_days,
            H: this.hours,
            M: this.minutes,
            S: parseFloat(`${this.seconds}.${nsecs ? this.msecs : 0}`)
        }).map(([k,v])=>v ? v + k : "").join("");
        return `PT${this.past ? "-" : ""}${string || '0S'}`;
    }
}