/* Copyright 2022- Martin Kufner */
const Names = {
    en: {
        Monthname: [
            ["Jan", "January"],
            ["Feb", "February"],
            ["Mar", "March"],
            ["Apr", "April"],
            ["May", "May"],
            ["Jun", "June"],
            ["Jul", "July"],
            ["Aug", "August"],
            ["Sep", "September"],
            ["Oct", "October"],
            ["Nov", "November"],
            ["Dec", "December"]
        ],
        Dayname: [
            ["Sun", "Sunday"],
            ["Mon", "Monday"],
            ["Tue", "Tuesday"],
            ["Wed", "Wednesday"],
            ["Thu", "Thursday"],
            ["Fri", "Friday"],
            ["Sat", "Saturday"]
        ],
        hours: ["hour", "hours"],
        minutes: ["minute", "minutes"],
        seconds: ["second", "seconds"],

    }
};

const Lang = function (value) {
    const langs = []
    if(typeof Lang.lang === "undefined" || value) {
        langs.push(value);
        if(typeof document !== "undefined") langs.push(document.documentElement.lang);
        if(typeof navigator !== "undefined") langs.push(...navigator.language.split(",").map(l => l.substr(0, 2)));
        langs.filter(l => l && Names[l])
    }
    if(langs.length) Lang.lang = langs[0];
    return Lang.lang;
}


export class Strftime {

    // Date
    // %Y - Year including century, zero-padded:
    // %y - Year without century, in range (0.99), zero-padded:
    // %C - Century, zero-padded:
    // %m - Month of the year, in range (1..12), zero-padded:
    // %B - Full month name, capitalized:
    // %b - Abbreviated month name, capitalized:
    // %h - Same as %b.
    // %d - Day of the month, in range (1..31), zero-padded:
    // %e - Day of the month, in range (1..31), blank-padded:
    // %j - Day of the year, in range (1..366), zero-padded:

    // Weekday
    // %A - Full weekday name:
    // %a - Abbreviated weekday name:
    // %u - Day of the week, in range (1..7), Monday is 1
    // %w - Day of the week, in range (0..6), Sunday is 0

    // Week number
    // %U - Week number of the year, in range (0..53), zero-padded, where each week begins on a Sunday:
    // %W - Week number of the year, in range (0..53), zero-padded, where each week begins on a Monday:

    // Week Dates
    // %G - Week-based year
    // %g - Week-based year without century, in range (0..99), zero-padded:
    // %V - Week number of the week-based year, in range (1..53), zero-padded:

    // Time
    // %H - Hour of the day, in range (0..23), zero-padded:
    // %k - Hour of the day, in range (0..23), blank-padded:
    // %I - Hour of the day, in range (1..12), zero-padded:
    // %l - Hour of the day, in range (1..12), blank-padded:
    // %P - Meridian indicator, lowercase:
    // %p - Meridian indicator, uppercase:
    // %M - Minute of the hour, in range (0..59), zero-padded:
    // %S - Second of the minute in range (0..59), zero-padded:
    // %L - Millisecond of the second, in range (0..999), zero-padded:
    // * %N - Fractional seconds, default width is 9 digits (nanoseconds):
    // %s - Number of seconds since the epoch:

    // Time zone
    // %z - Timezone as hour and minute offset from UTC:
    // %Z - Timezone name (platform-dependent):
    // Timezone Flags
    // : - Put timezone as colon-separated hours and minutes: => "-05:00"
    // :: - Put timezone as colon-separated hours, minutes, and seconds:

    // Shorthand Conversion Specifiers
    // %c - Date and time  '%a %b %e %T %Y'  # => "Wed Jun 29 08:01:41 2022"
    // %D | %x - Date: '%m/%d/%y' # => "06/29/22"
    // %F - ISO 8601 date: '%Y-%m-%d' # => "2022-06-29"
    // %v - VMS date: '%e-%^b-%4Y' # => "29-JUN-2022"
    // %r - 12-hour time '%I:%M:%S %p'  # => "01:00:00 AM"
    // %R - 24-hour tine '%H:%M' # => "13:00"
    // %T | %X - 24-hour time w/ seconds '%H:%M:%S' # => "13:00:00"
    // %+ not supported

    static shorthand(format) {
        format = format.replace(/%[cDxFvrRTX]/g, m => {
            switch(m) {
                case "%c":
                    return '%a %b %e %T %Y';
                case "%D":
                case "%x":
                    return '%m/%d/%y';
                case "%F":
                    return '%Y-%m-%d';
                case "%v":
                    return '%e-%^b-%Y';
                case "%r":
                    return '%I:%M:%S %p';
                case "%R":
                    return '%H:%M';
                case "%T":
                case "%X":
                    return '%H:%M:%S';
            }
        });
        if(/%[cDxFvrRTX]/.test(format)) return this.shorthand(format);
        return format;
    };

    static re = /(?:(%)([\?><])?([0_$^:\-])?([AaBbCdeGghHIjklLmMNPpSsuUVwWYyzZ]))|(%[nt])|(%%|[^%]+)/g

    parts = [];
    #date;

    constructor(...args) {
        this.format = args.find(arg => typeof arg === 'string') || "%c";
        this.date = args.find(arg => arg instanceof Date);
    }

    get format() { return this._format }

    set format(format) {
        this._format = this.constructor.shorthand(format);
        const matches = [...this._format.matchAll(this.constructor.re)];
        matches.forEach(([match, isFormat, type, flag, format, sep], i) => {
            if(isFormat) switch(format) {
                case "N":
                    return; // ignore
                case "h":
                    format = "b";
                default:
                    this.method(format, flag, type);
            }
            else if(sep === "%n") this.parts.push("\n");
            else if(sep === "%t") this.parts.push("\t");
            else this.text(match);
        });
    }

    method(format, flag, type) {
        switch(flag) {
            case "$":
                format = `$${format}`;
                break;
            case ":":
            case "^":
                format = flag + format;
                break;
            case "0":
            case "-":
            case "_":
        }
        let places = 1, pad = '0';
        if(/[YGg]/.test(format)) places = 4;
        else if(/[jL]/.test(format)) places = 3;
        else if(/[yCmdIMSUWVHI]/.test(format)) places = 2;
        else if(/[ekl]/.test(format)) {
            places = 2;
            pad = " ";
        }
        else pad = undefined;

        format = '%' + format;
        if(!this[format]) return;
        this.parts.push(Object.assign(this[format].bind(this), {format, flag, type, pad, places}));
    }

    get last() { return this.parts[this.parts.length - 1] }

    text(text) {
        const last = this.last;
        if(typeof last === "string" && !/^[\n\t]$/.test(last)) this.parts[this.parts.length - 1] += text;
        else this.parts.push(text);
    }

    set date(date) {
        this.#date = date;
    }

    get date() { return new Date(this.#date); }

    values(date) {
        if(date) this.date = date;
        if(!this.date.isValid) return [];
        return this.parts.filter(p => p).map(part => typeof part === "string" ? part : part())
    }

    toString(date) { return this.values(date).join(""); }

    zone(zone) {
        if(zone && zone !== 'UTC') throw 'zones not implemented yet';
        this._zone = zone;
        return this;
    }

    get(part) { return this.date[`get${this._zone || ''}${part}`](); }

    translate(kind, n) {
        let i = 0;
        switch(kind) {
            case "Monthname":
                i = this.months - 1;
                break;
            case "Dayname":
                i = this.wday;
                break;
            default:
                i = n === 1 ? 0 : 1;
                n = null;
        }
        if(isNaN(i)) return NaN;
        let basis;
        try {
            basis = Names[Lang()][kind][i];
        }
        catch(e) {
            basis = Names.en[kind][i];
        }
        return n === null ? basis : basis[n ? 0 : 1];
    }

    get months() { return this.get('Month') + 1; }

    get years() { return this.get('FullYear'); }

    get wday() { return this.get('Day'); }

    get days() { return this.get('Date'); }

    get hours() { return this.get('Hours'); }

    get minutes() { return this.get('Minutes'); }

    get seconds() { return this.get('Seconds'); }

    get milliseconds() { return this.get('Milliseconds'); }

    ['%$D']() { return this.translate("days", this.#j); }

    ['%$H']() { return this.translate("hours", this.hours); }

    ['%$M']() { return this.translate("minutes", this.minutes); }

    ['%$S']() { return this.translate("seconds", this.seconds); }

    //Year including century, zero-padded:
    ['%Y']() { return this.years.toString().padStart(4, '0') }

    //Year without century, in range (0.99), zero-padded:
    ['%y']() { return (this.years % 100).toString().padStart(2, '0') }

    //Century, zero-padded:
    ['%C']() { return Math.floor(this.years / 100).toString().padStart(2, '0') }

    //Month of the year, in range (1..12), zero-padded:
    ['%m']() { return this.months.toString().padStart(2, '0') }

    // Full month name, capitalized:
    ['%B']() { return this.translate("Monthname"); }

    ['%^B']() { return this['%B']()?.toUpperCase(); }

    // Abbreviated month name, capitalized:
    ['%b']() { return this.translate("Monthname", true); }

    ['%^b']() { return this['%b']()?.toUpperCase(); }

    //Day of the month, in range (1..31), zero-padded:
    ['%d']() { return this.days.toString().padStart(2, '0'); }

    //Day of the month, in range (1..31), blank-padded:
    ['%e']() { return this.days.toString().padStart(2, ' '); }

    //Day of the year, in range (1..366), zero-padded:
    get #j() { return Math.ceil(((this.date - (new Date(this.years, 0, 1) - 1)) / 86400000)); }

    ['%j']() { return this.#j.toString().padStart(3, '0') }

    // Weekday

    //Full weekday name:
    ['%A']() { return this.translate("Dayname"); }

    //Abbreviated weekday name:
    ['%a']() { return this.translate("Dayname", true); }

    //Day of the week, in range (1..7), Monday is 1
    ['%u']() { return this.wday || 7; }

    //Day of the week, in range (0..6), Sunday is 0
    ['%w']() { return this.wday; }

    // Week number
    get #U() { return Math.ceil(this.#j / 7); }

    //Week number of the year, in range (0..53), zero-padded, where each week begins on a Sunday:
    ['%U']() { return this.#U.toString().padStart(2, '0'); }

    //Week number of the year, in range (0..53), zero-padded, where each week begins on a Monday:
    ['%W']() {
        const diff = new Date(this.years, 0, 1).wday ? 0 : 1;
        return (this.#U - diff).toString().padStart(2, '0');
    }

    // Week Dates

    //Week-based year
    ['%G']() { return "G not implemented" }

    //Week-based year without century, in range (0..99), zero-padded:
    ['%g']() { return "g not implemented" }

    //Week number of the week-based year, in range (1..53), zero-padded:
    ['%V']() { return "V not implemented" }

    // Time

    //Hour of the day, in range (0..23), zero-padded:
    ['%H']() { return this.hours?.toString()?.padStart(2, '0'); }

    //Hour of the day, in range (0..23), blank-padded:
    ['%k']() { return this.hours?.toString()?.padStart(2, ' '); }

    //Hour of the day, in range (1..12), zero-padded:
    ['%I']() { return (this.hours % 12)?.toString()?.padStart(2, '0'); }

    //Hour of the day, in range (1..12), blank-padded:
    ['%l']() { return (this.hours % 12)?.toString()?.padStart(2, ' '); }

    //Meridian indicator, lowercase:
    ['%P']() {
        const hours = this.hours
        if(isNaN(hours)) return NaN;
        return hours < 12 ? "am" : "pm";
    }

    //Meridian indicator, uppercase:
    ['%p']() { return this['%P']()?.toUpperCase(); }

    //Minute of the hour, in range (0..59), zero-padded:
    ['%M']() { return this.minutes?.toString()?.padStart(2, '0'); }

    //Second of the minute in range (0..59), zero-padded:
    ['%S']() { return this.seconds?.toString()?.padStart(2, '0'); }


    //Millisecond of the second, in range (0..999), zero-padded:
    ['%L']() { return this.milliseconds?.toString()?.padStart(3, '0'); }

    // * %N () {} //Fractional seconds, default width is 9 digits (nanoseconds):

    //Number of seconds since the epoch:
    ['%s']() { return Math.floor(this.date / 1000); }

    // Time zone
    get #timezone() {
        const [_, offset, name] = this.date.toString().match(/GMT(\S+)(?: \(([^()]+)\))/) || [],
            zone = name?.replace(/[^A-Z]/g, '');
        return {
            name, zone, offset
        };
    }

    //Timezone as hour and minute offset from UTC:
    ['%z']() {
        switch(this._zone) {
            case 'UTC':
                return "+0000";
            case undefined:
                return this.date?.getTimezone() || NaN;
        }
    }

    //Timezone as hour and minute offset from UTC:
    ['%:z']() { return this['%z']().replace(/(\d\d)$/, ":$1") }

    //Timezone name (platform-dependent):
    ['%Z']() {
        switch(this._zone) {
            case 'UTC':
            case '+00:00':
                return "UTC";
            case undefined:
                this.#timezone?.zone;
        }
    }

    ['%Z']() {
        // Timezone Flags
        return this['%Z']()
    }
}

const instance_properties = {
    strftime_object: {
        value(format_string) { return new Strftime(this, format_string); }
    },

    strftime: {
        value(format_string) { return new Strftime(format_string, this).toString(); }
    }


    // : - Put timezone as colon-separated hours and minutes: => "-05:00"
    // :: - Put timezone as colon-separated hours, minutes, and seconds:
};

Date.Strftime = Strftime;
Object.defineProperties(Date.prototype, instance_properties);

