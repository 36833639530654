/* Copyright 2022- Martin Kufner */

Object.defineProperties(URL.prototype, {
   eql: {
       value(other) {
           try {
               if(!(other instanceof URL)) other = new URL(other);
               if(this.origin !== other.origin) return false;
               if(this.pathname.replace(/\/$/, "") !== other.pathname.replace(/\/$/, "")) return false;
               for(const [key, value] of this.searchParams) {
                   if(other.searchParams.get(key) === null) return false;
                   other.searchParams.delete(key, value);
               }
               return other.searchParams.size === 0;
           }
           catch(e) {
               return false;
           }
       }
   }
});