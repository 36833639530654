/* Copyright 2022- Martin Kufner */
let handle = 0
const promises = {}


class PromiseExtendedThen extends Promise {
    static name = "PromiseExtended";
    static get [Symbol.species]() {
        return this;
    }

    resolve(value) {
        return this.promise.resolve(value);
    }
    reject(value) {
        return this.promise.reject(value);
    }
    get clear() {
        return this.promise.clear;
    }
    get abort() {
        return this.promise.abort;
    }
    set timeout(value) {
        this.promise.timeout = value
    }
    get details() {
        return this.promise.details;
    }
}
class PromiseException extends Error {
    constructor(details) {
        super("exception");
        this.details = details;
    }
}


class PromiseExtended {
    static race(promises, options) {
        const promise = new this(options);
        Promise.race(promises).then(p=>promise.resolve(p)).finally(()=>promises.forEach(p=>p.abort));
        return promise;
    }

    #timeout_handle;
    #exception;
    constructor(options) {
        const promise = this;
        const PromiseExtended = class extends PromiseExtendedThen { promise = promise };
        this.promise = new PromiseExtended((resolve, reject) => Object.assign(this, {resolve, reject}));
        this.promise.finally(()=>this.clear);
        if(typeof options !== 'object') return;
        if('details' in options) this.details = options.details;
        if('timeout' in options) this.timeout = options.timeout;
        this.#exception = new PromiseException(this.details);
    }
    raise(name) {
        this.#exception.message = name;
        this.reject(this.#exception);
    }
    set timeout(value) {
        this.clear;
        if(value && value > 0) this.#timeout_handle = setTimeout(()=>this.raise('timeout'), value);
    }

    get abort() {
        this.clear;
        this.raise('abort');
    }

    get clear() {
        clearTimeout(this.#timeout_handle);
    }

    then() { return this.promise.then(...arguments)}
    catch(error) { return this.promise.catch(e=>{
        if(e === this.#exception) throw(e);
        return error(e);
    })}
    finally(f) { return this.promise.finally(f)}
}



Object.defineProperties(Promise, {
    create: {
        value(...args) {
            const rv = typeof args[0] === 'object' ? args.shift() : {};
            let [promise, resolve, reject] = args;
            if(promise?.endsWith("_")) {
                if(!resolve) resolve = `${promise}resolve`;
                if(!reject) reject = `${promise}reject`;
                promise = `${promise}promise`;
            }
            rv[promise || `promise`] = new Promise((y, n) => {
                rv[resolve || `resolve`] = y;
                rv[reject || `reject`] = n
            });
            return rv;
        }
    },

    make: {
        get() {
            const rv = {},
                promise = new Promise((resolve, reject) => Object.assign(rv, {resolve, reject}));
            return {
                promise,
                then: f => promise.then(f),
                catch: e => promise.catch(e),
                finally: () => promise.finally(), ...rv
            };
        }

    },
    timed: {
        value(timeout, details) {
            const {promise, resolve, reject} = Promise.make;

            Object.defineProperties(promise, {
                details: {value: details},
                resolve: {value: resolve},
                reject: {value: reject},
                clear: {
                    get() {
                        console.info('cleared', this.details);
                        clearTimeout(this.handle);
                    }
                },
                abort: {
                    get() {
                        clearTimeout(this.handle);
                        console.info('aborted', this.details);
                        this.reject('aborted');
                    }
                },
                timeout: {
                    set(value) {
                        this.clear;
                        this.handle = setTimeout(() => {
                            console.info('timedout', this.details);
                            this.reject("timedout");
                        }, value);
                    }
                }
            });
            if(timeout) promise.timeout = timeout;
            promise.finally(()=>promise.clear);
            //
            //     const handle = setTimeout(() => {
            //         console.warn('timedout', details);
            //         reject("timedout");
            //     }, timeout);
            //     Object.defineProperty(promise, 'clear', {
            //         get: function () {
            //             console.warn('cleared', details);
            //             clearTimeout(handle);
            //         }
            //     });
            //     promise.finally(() => clearTimeout(handle));
            // }
            return {resolve, reject, promise};
        }
    },
    Extended: {
        value: PromiseExtended
    }
});
// Object.defineProperties(Promise.prototype, {
//     timeout: {
//         value()
//     }
// });