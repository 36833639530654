/* Copyright 2022- Martin Kufner */
import {cT} from "./content-tag.js";

export class Caret {
    static block_re = /div|li/i;

    static isBlock(node) { return this.block_re.test(node.nodeName) }

    constructor(target, brTagName="div") {
        this.target = target;
        this.brTagName = brTagName;
        const base = (document.documentElement.contains(target)) ? window : target.ancestors().pop(),
            {focusNode, anchorNode} = this.selection = base.getSelection(),
            focusNodeWithin = target.contains(focusNode),
            anchorNodeWithin = target.contains(anchorNode);

        if(focusNodeWithin && anchorNodeWithin) return;
        else if(focusNodeWithin) this.selection.setBaseAndExtent(focusNode, focusOffset, focusNode, focusOffset);
        else if(anchorNodeWithin) this.selection.setBaseAndExtent(anchorNode, anchorOffset, anchorNode, anchorOffset);
        this.selection.selectAllChildren(target);
        this.selection.collapseToEnd();
    }

    // returns all nodes of the container flattened with offset from start by character, not first element child block nodes add a newline before
    nodes(target = this.target) {
        if(this.caching) {
            if(this._nodes) return this._nodes?.find(([t]) => t === target)?.at(1);
        }
        else delete this._nodes;
        const nodes = [],
            iterator = document.createNodeIterator(target, NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT),
            range = this.range;
        let node = iterator.nextNode(), offset = 0;
        let ancestors = [], block_before = false;
        while((node = iterator.nextNode())) {
            if(isNaN(offset)) offset = -range.startOffset;
            const obj = [node];
            const _ancestors = node.ancestors(target, false).filter(n => Caret.isBlock(n));
            if(!Object.equal(_ancestors, ancestors)) {
                ancestors = _ancestors;
                if(node.parentNode.firstChild !== node) {
                    block_before = true;
                    offset++;
                }
            }
            obj.push(offset, block_before, range.intersectsNode(node));
            nodes.push(obj);
            if(node instanceof Text) {
                block_before = false;
                offset += node.data.length;
            }
        }
        if(this.caching) {
            if(!this._nodes) this._nodes = [];
            this._nodes.push([target, nodes]);
        }
        return nodes;
    }

    #getLastNodeByOffset(value) {
        const nodes = this.nodes();
        return nodes.findLast(([_, offset]) => offset <= value) || nodes[0];
    }

    getTexts() { return this.target.getTextNodes.map(t => t.data) }

    /*
    * Get the character offset within the target
    *
    * @overload #offset(type)
    *   @param type [anchor,focus] of the selection anchor|focus
    * @overload #offset(type)
    *   @param type [start,end] of the current range start|end
    * @overload #offset(type, range)
    *   @param type [start,end] of the range start|end
    *   @param range [Range]
    *
    * @return [Integer] character offset from the start of the target
    */
    #offset(type, range) {
        const obj = /anchor|focus/.test(type) ? this.selection : range || this.range;
        const sNode = obj instanceof Range ? obj[`${type}Container`] : obj[`${type}Node`],
            sOffset = obj[`${type}Offset`];
        let rv = 0;
        for(const [node, offset] of this.nodes()) {
            if(node !== sNode) continue;
            if(node instanceof Text) rv += offset + sOffset;
            else rv += [...node.childNodes].slice(0, offset)
                .reduce((p, c) => p + (c.data || c.innerText)?.length || 0, 0);
            return rv;
        }
        return 0;
    }

    // Get the focus character offset relative to the start of the target container
    get focus() { return this.#offset('focus') }

    get targetLength() {
        const range = new Range();
        range.selectNodeContents(this.target);
        return this.#text(range).join("").length;
    }

    #setFocus(node, offset) {
        const {anchorNode, anchorOffset} = this.selection;
        offset = Math.max(0, Math.min(offset, node instanceof Text ? node.data.length : node.childNodes.length));
        this.selection.setBaseAndExtent(
            anchorNode || node, anchorOffset !== undefined ? anchorOffset : offset, node, offset
        );
    }

    // sets the focus of the selection
    // positive values start from begin of the target
    // negative values start from the end of the target todo
    set focus(value) {
        if(value < 0) value += this.targetLength + 1;
        const [node, offset] = this.#getLastNodeByOffset(value);
        this.#range = undefined;
        this.#setFocus(node, value - offset);
    }

    get anchor() { return this.#offset('anchor') }

    #setAnchor(node, offset) {
        const {focusOffset, focusNode} = this.selection;
        offset = Math.max(0, Math.min(offset, node instanceof Text ? node.data.length : node.childNodes.length));
        this.selection.setBaseAndExtent(
            node, offset, focusNode || node, focusOffset !== undefined ? focusOffset : offset
        );
    }

    set anchor(value) {
        if(value < 0) value += this.targetLength + 1;
        const [node, offset] = this.#getLastNodeByOffset(value);
        this.#range = undefined;
        this.#setAnchor(node, value - offset);
    }

    #getStart(range) { return this.#offset('start', range) }

    get start() { return this.#offset('start') }

    #setStart(value, range) { this.#setStartEnd("setStart", value, range);}

    set start(value) { this.#setStart(value); }

    #getEnd(range) { return this.#offset('end', range) }

    get end() { return this.#offset('end') }

    #setStartEnd(method, value, range) {
        let node, offset;
        switch(value) {
            case "focus":
                node = this.selection.focusNode;
                offset = this.selection.focusOffset;
                break;
            case "anchor":
                node = this.selection.anchorNode;
                offset = this.selection.anchorOffset;
                break;
            case "start":
                node = this.target;
                offset = 0;
                break;
            case "end":
                node = this.target;
                offset = this.target.childNodes.length;
                break;
            default:
                if(isNaN(value)) return;
                if(value < 0) value += this.targetLength + 1;
                [node, offset] = this.#getLastNodeByOffset(value);
                offset = Math.max(0, Math.min(value - offset, node instanceof Text ? node.data.length : node.childNodes.length));
        }
        (range || this.#detachRange)[method](node, offset);
    }

    #setEnd(value, range) { this.#setStartEnd("setEnd", value, range);}

    set end(value) { this.#setEnd(value); }

    get collapseToFocus() {
        this.selection.collapse(this.selection.focusNode, this.selection.focusOffset);
        this.range = undefined;
        return this;
    }

    get collapseToAnchor() {
        this.selection.collapse(this.selection.anchorNode, this.selection.anchorOffset);
        this.range = undefined;
        return this;
    }

    get collapseToStart() {
        this.selection.collapseToStart();
        this.range = undefined;
        return this;
    }

    get collapseToEnd() {
        this.selection.collapseToEnd();
        this.range = undefined;
        return this;
    }


    get inspect() {
        const {focusNode, focusOffset, anchorNode, anchorOffset} = this.selection;
        const {startContainer, startOffset, endContainer, endOffset} = this.range || {};
        const {anchor, focus, start, end, text, target} = this;
        return {
            selection: {anchorNode, anchorOffset, focusNode, focusOffset},
            range: {startContainer, startOffset, endContainer, endOffset},
            caret: {anchor, focus, start, end, text, matched: this.matched},
            target: {
                target,
                innerHTML: target.innerHTML,
                innerText: target.innerText,
                selectedText: target.innerText.substring(Math.min(anchor, focus), Math.max(anchor, focus)),
                rangeText: target.innerText.substring(start, end)
            }
        };
    }

    #text(range) {
        const rv = [];
        for(const [node, offset, break_before] of this.nodes()) {
            if(!(node instanceof Text) || !range.intersectsNode(node)) continue;
            let text = node.data;
            const startInRange = range.comparePoint(node, 0),
                endInRange = range.comparePoint(node, text.length);
            if(endInRange > 0) text = text.substring(0, range.endOffset);
            if(startInRange < 0) text = text.substr(range.startOffset);
            if(startInRange === 0 && break_before && rv.length) rv.push("\n", text);
            else rv.push(text);
        }
        return rv;
    }

    get text() {
        const range = this.range;
        return range ? this.#text(range).join("") : "";
    }

    get selectionRange() {
        if(this.selection.rangeCount) return this.selection.getRangeAt(0);
    }

    #range;
    get range() {
        return this.#range || (this.range = this.selectionRange);
    }

    set range(value) {
        delete this.matched;
        this.#range = value;
    }

    get resetRange() {
        this.range = this.selectionRange;
        return this;
    }

    get #detachRange() { return this.#range = this.range.cloneRange();}

    get detachRange() {
        this.#detachRange;
        return this;
    }

    get selectAll() {
        this.select(0, this.targetLength);
        return this;
    }

    get rangeAll() {
        this.start = 0;
        this.end = this.targetLength;
        return this;
    }

    /**
     * Sets anchor and/or focus by range or numbers
     *
     * @overload select(range)
     *      @param anchor_or_range [Range] set anchor and focus
     * @overload select(anchor, focus)
     *      @param anchor [Number, String, optional] set anchor. If is a String and starts with either + or - it increments/decrements
     *      @param focus [Number, String, optional] set end. If is a String and starts with either + or - it increments/decrements
     * @return [Caret] this
     */
    select(anchor_or_range, focus) {
        if(focus === undefined && anchor_or_range === undefined) anchor_or_range = this.#range;
        if(anchor_or_range === undefined) return;
        this.#range = undefined;
        switch(Object.type(anchor_or_range)) {
            case "Range":
                if(this.selection.rangeCount) this.selection.removeAllRanges();
                this.selection.addRange(anchor_or_range);
                return this;
            case "String":
                if(/^[+-]\d+/.test(anchor_or_range)) anchor_or_range = this.anchor + anchor_or_range;
            case "Number":
                if(!isNaN(anchor_or_range)) this.anchor = anchor_or_range;
        }
        switch(Object.type(focus)) {
            case "String":
                if(/^[+-]\d+/.test(focus)) focus = this.focus + focus;
            case "Number":
                if(!isNaN(focus)) this.focus = focus;
        }
        return this;
    }

    get #focusRange() {
        const range = this.#focusStartRange;
        range.collapse();
        return range;
    }

    get #focusStartRange() {
        const range = new Range();
        range.setStart(this.target, 0);
        range.setEnd(this.selection.focusNode, this.selection.focusOffset);
        return range;
    }

    get #focusEndRange() {
        const range = new Range();
        range.setStart(this.selection.focusNode, this.selection.focusOffset);
        range.setEnd(this.target, this.target.childNodes.length);
        return range;
    }

    #caching;
    get caching() { return this.#caching }

    set caching(value) { (this.#caching = value) || delete this._nodes; }

    focusMatch(regexp) {
        delete this.matched;
        this.resetRange;
        this.caching = true;
        if(!/g/.test(regexp.flags)) regexp = new RegExp(regexp, regexp.flags + 'g');
        const found = [...this.target.innerText.matchAll(regexp)].some(m => {
            this.start = m.index;
            this.end = m.index + m[0].length;
            if(!this.#range.isPointInRange(this.selection.focusNode, this.selection.focusOffset)) return;
            this.matched = Object.assign(m, {range: this.#range});
            return true;
        });
        this.caching = false;
        if(!found) {
            this.#range = new Range();
            delete this.matched;
        }
        return this;
    }

    /**
     * matches start or end with center focus, and sets the range
     *
     * @param start [RegExp, optional] matching from target start to focus if undefined it is set to focus
     * @param end [RegExp, optional] matching from focus to target end if undefined it is set to focus
     * @return [Caret] this
     */
    rangeMatch(start, end) {
        delete this.matched;
        let before, after;
        if(Object.type(start) === "RegExp") {
            const range = this.#focusStartRange;
            before = this.match(start, range);
        }
        if(Object.type(end) === "RegExp") {
            const range = this.#focusEndRange;
            after = this.match(end, range);
        }
        if(!start && !end) return this;
        if(before || after) this.matched = {before, after};
        const range = new Range(),
            {focusNode, focusOffset} = this.selection,
            {startContainer, startOffset} = before?.range || {
                startContainer: focusNode,
                startOffset: focusOffset
            },
            {endContainer, endOffset} = after?.range || {
                endContainer: focusNode,
                endOffset: focusOffset
            };
        range.setStart(startContainer, startOffset);
        range.setEnd(endContainer, endOffset);
        this.#range = range;

        return this;
    }

    /**
     * Detach range and set start and/or end
     * @param start [Number, String, optional] set start.
     *      If is a String and starts with either + or - it increments/decrements.
     * @param end [Number, String, optional] set end.
     *      If is a String and starts with either + or - it increments/decrements.
     * @return [Caret] this
     */
    setRange(start, end) {
        switch(Object.type(start)) {
            case "String":
                if(/^[+-]\d+/.test(start)) start = this.start + start;
            case "Number":
                this.start = start;
        }
        switch(Object.type(end)) {
            case "String":
                if(/^[+-]\d+/.test(end)) end = this.end + end;
            case "Number":
                this.end = end;
        }
        return this;
    }

    modifyRange(fn = r => r) { return this.range = fn.call(this, this.range) }

    /**
     * Match the first occurrence of the regex in the range and sets this._match with the match results
     *
     * @param regex [RegExp]
     * @param range [Range, optional]
     * @return [Caret] this
     */
    match(regex, range) {
        delete this.matched;
        if(!range) range = this.#detachRange;
        const text = this.#text(range).join(""),
            match = text.match(regex);
        if(!match) return;
        const start = this.#getStart(range) + match.index,
            end = start + match[0].length;
        this.#setStart(start, range);
        this.#setEnd(end, range);
        return this.matched = Object.assign(match, {start, end, range});
    }

    get delete() {
        this.selection.deleteFromDocument();
        this.resetRange;
    }

    get extractText() {
        const rv = this.text;
        this.delete;
        return rv;
    }

    set char(char) {
        if(!char) return;
        this.delete;
        switch(char) {
            case " ":
                this.range.insertNode(new Text('\xa0'));
                break;
            case "\n":
                if(this.selection.focusNode instanceof Text) {}
                else {
                    const node = cT(this.brTagName, this.range.extractContents());
                    this.selection.focusNode.after(node);
                    this.#setFocus(node, 0);
                    this.#setAnchor(node, 0);
                    this.resetRange;
                }
                debugger;
                break;
            default:
                this.range.insertNode(new Text(char));
        }
        this.collapseToEnd;
    }

    set text(text) {
        this.delete;
        const texts = text.split(/\n+/);
        let last;
        if(this.selection.focusNode instanceof Text) {
            this.range.insertNode(new Text(texts.shift()));
            last = texts.pop();
        }
        if(texts.length) this.range.insertNode(cT.Fragment(...texts.map(t => cT(this.brTagName, t))));
        if(last) this.range.insertNode(new Text(last));
        this.collapseToEnd;
    }

    insert(...nodes) {
        console.error('Not implemented');
    }
}

Selection.Caret = node => new Caret(node);

// test
// import "./test/caret.js";