/* Copyright 2022- Martin Kufner */
import '../extensions/promise.js';
import '../extensions/object.js';
import {cT} from "../content-tag.js";
import {QBElementsCSS} from "/qb-elements.css.js";

export {cT};

Reflect.defineProperty(window, "customElement", {
    value(klass, nodeName) {
        if(!nodeName) nodeName = klass.nodeName;
        window.customElements.get(nodeName) || window.customElements.define(nodeName, klass);
    }
});

Reflect.defineProperty(Comment.prototype, "parse", {
    get() {
        try {
            return JSON.parse(this.data.replace(/^\[CDATA\[|\]\]$/g, ''));
        }
        catch(e) { return null }
    }
});

const documentStyleSheet = new CSSStyleSheet();
document.adoptedStyleSheets = [...document.adoptedStyleSheets, documentStyleSheet];

class QBCSSStyleSheet {
    constructor(...css) {
        try {
            this.stylesheet = new CSSStyleSheet();
        }
        catch(e) {
        }
        this.replaceSync(css.filter(s => s).join(";"));
    }

    replaceSync(css) {
        this.stylesheet.replaceSync(css);
        this.hostStylesheet = new CSSStyleSheet();
        [...this.stylesheet.rules].forEach(({selectorText, cssText}) => {
            if(!/:host/.test(selectorText)) return documentStyleSheet.insertRule(cssText);
            selectorText = selectorText.replace(/(^|\n|,|\s*)[a-z0-9-_]+:host/ig, "$1:host");
            cssText = cssText.replace(/^[^{]+/, '')
            this.hostStylesheet.insertRule(selectorText + cssText);
        })

    }

    get present() {
        return this.hostStylesheet.rules.length != 0;
    }

    adopt(node) {
        if(this.present) node.adoptedStyleSheets = [this.hostStylesheet];
    }


    static init(node) {
        const tagName = node.tagName.toLowerCase(),
            klass = node.constructor,
            tagNames = [...(klass.styleSheetTagNames || []), tagName];
        const stylesheet = new this(...tagNames.map(tagName => QBElementsCSS[tagName]), klass.defaultStyleSheet);
        if(stylesheet.present) return stylesheet;
    }
}

export class QBElement extends HTMLElement {
    static get nodeName() {
        return this.name.replace(/([a-z0-9])([A-Z])/g, "$1-$2").replace(/(.)([A-Z][a-z0-9])/g, '$1-$2').replace(/-+/, '-').toLowerCase();

        // return this.name.replace(/([A-Z][a-z0-9])/g, "-$1").toLowerCase().replace(/^-|-$/, '');
    }
    static get registerAccessors() {
        const accessors = {};
        const add = (p, props) => {
            if(p in accessors) Object.assign(accessors[p], props);
            else accessors[p] = props;
        }
        this.datasetAccessor?.split(/\s+/)?.forEach(p=>add(p, {s: true, g: true}));
        this.datasetReader?.split(/\s+/)?.forEach(p=>add(p,{g: true}));
        this.datasetWriter?.split(/\s+/)?.forEach(p=>add(p, {s: true}));
        Object.entries(accessors).forEach(([p, {s, g}]) => {
            const {get, set} = Object.getPropertyDescriptors(this.prototype)[p] || {},
                prop = {};
            if(g && get === undefined) prop.get = function () { return this.dataset[p] }
            if(s && set === undefined) prop.set = function (v) {
                if(v === undefined || v === null) delete this.dataset[p];
                this.dataset[p] = v;
            }
            Reflect.defineProperty(this.prototype, p, prop);
        });
    }

    static get register() {
        this.registerAccessors;
        const nodeName = this.nodeName;
        const registry = self.customElements;
        // registry.get(nodeName) ||
        registry.define(nodeName, this);
    }

    static styleSheet(node) {
        if(!("_styleSheet" in this)) this._styleSheet = QBCSSStyleSheet.init(node);
        return this._styleSheet;
    }

    constructor() {
        super();
        this.contentRoot = this;
        if(this.constructor.shadow) this.contentRoot = this.attachShadow({mode: this.constructor.shadow});
        this.constructor.styleSheet(this)?.adopt(this.contentRoot);
        if(this.constructor.template) this.content = this.constructor.template;
        if(this.__lookupGetter__('init')) setTimeout(() => {
            this.init;
            this.isInitialized = true;
            if(this.#_initializedPromise) this.#_initializedPromise.resolve(this);
        });
        else this.isInitialized = true;
    }

    set content(value) {
        if(typeof value === "string") return this.contentRoot.innerHTML = value;
        this.contentRoot.innerHTML = "";
        if(value.nodeName === "TEMPLATE") value = value.content;
        this.contentRoot.appendChild(value.cloneNode(true));
        cT.eventTarget(this, this.contentRoot);
    }

    #_initializedPromise;

    get #initializedPromise() {
        if(!this.#_initializedPromise) return this.#_initializedPromise = Promise.create();
        return this.#_initializedPromise;
    }

    get initialized() {
        return this.isInitialized ? Promise.resolve(this) : this.#initializedPromise.promise;
    }

    #_connectedPromise;

    get #connectedPromise() {
        if(!this.#_connectedPromise) return this.#_connectedPromise = Promise.create();
        return this.#_connectedPromise;
    }

    get connected() {
        return this.isConnected ? Promise.resolve(this) : this.#connectedPromise.promise;
    }

    connectedCallback() {
        if(this.#_connectedPromise) this.#_connectedPromise.resolve(this);
        this.listen('update', e => this.update(e.detail));
    }

    #isUpdated = Promise.create();
    get isUpdated() { return this.#isUpdated.promise }

    __update__(data, only) {
        Object.entries(data).forEach(([key, value]) => {
            if(only && !only.includes(key)) return;
            if(key === "removed") return;
            delete data[key];
            if(key === 'id') key = "ID";
            try {
                if(key.endsWith("_at") && value) value = Date.utc(value);
                let fn = this[`update_${key}`];
                if(fn) return fn.call(this, value);
                fn = this.__lookupSetter__(key);
                if(fn) return fn.call(this, value);
            }
            catch(e) {
                console.error(this.constructor.name, e);
            }
        });
    }

    async update(data) {
        if(data === false) return this.remove();
        if(typeof data !== "object") return;
        if("removed" in data) {
            if(data.removed === true) return this.remove();
            if(typeof this.removed === "function" && this.removed(data.removed) !== false) return;
        }
        this.__update__(data, this.constructor.updateBeforeInitialized);
        await this.initialized;
        this.__update__(data);
        this.#isUpdated.resolve(this);
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if(oldValue === newValue) return;
        // console.log(name, oldValue,"->", newValue);
        if(newValue === "true" || newValue === name) newValue = true;
        else if(newValue === "false") newValue = false;
        this[`$${name}`]?.call(this, newValue, oldValue);
    }

    updateOptions(...options) { return cT(this, options); }

    dispatch(type, detail) {
        // if(/^(change)$/.test(type)) this.dispatchEvent(new Event(type));
        this.dispatchEvent(new CustomEvent(type, {bubbles: true, detail: detail || {}}));
    }

    listen(type, cb) {
        if(typeof cb === "string") cb = this[cb].bind(this);
        if(cb !== undefined && typeof cb !== "function") return console.trace(`listen(${type}, ${cb}) cb not a function`);
        this.addEventListener(type, cb || this)
    }

    events(...events) { return cT(this, {events}); }

    get clear() {
        this.textContent = "";
        return this
    }

    get CDATA_JSON() {
        return Array.from(this.childNodes).filter(n => n.nodeType === 8).map(n => {
            try {
                const m = n.data.match(/^\[CDATA\[[^\{]*(\{.*\})[^\}]*\]\]$/);
                if(m) return JSON.parse(m[1]);
            }
            catch(e) {
            }
        }).filter(n => n);
    }

    addClass(...args) { this.classList.add(...args) }

    hasClass(...args) { return this.classList.contains(...args) }

    removeClass(...args) { this.classList.remove(...args) }

    toggleClass(...args) { return this.classList.toggle(...args) }

    observe(fn, options) {
        options = Object.assign({childList: true}, options);
        if(this.childChangeObserver) this.childChangeObserver.disconnect();
        this.childChangeObserver = new MutationObserver((m, o) => fn(m, o)).observe(this, options);
    }

    set itemtype(value) {
        this.setAttribute('itemscope', '');
        this.setAttribute('itemtype', `${location.origin}/schema/${value}`);
    }

    get itemtype() {
        return this.getAttribute('itemtype')?.replace(/^.*\//, '');
    }

    set itemid(value) {
        const m = value.match(/^([^_]+)_(.*)$/);
        this.setAttribute('itemscope', '');
        if(m) value = m[2];
        if(value) this.setAttribute('itemid', value);
        else this.removeAttribute('itemid');
        if(m) this.itemtype = m[1];
    }

    get itemid() { return this.getAttribute('itemid') }

    styleProperties(value) {
        Object.entries(value).forEach(([k, v]) => {
            k = k.replace(/^-*/, "--");
            if(v !== undefined) this.node.style.setProperty(k, v);
            else this.node.style.removeProperty(k);
        });
    }

    nameElements(name) {
        if(name) return this.querySelector(`[name="${name}"]`);
        const rv = Array.from(this.querySelectorAll("[name]"));
        Object.defineProperty(rv, "get", {
            value(name) { return this.find(n => n.name === name) }
        });
        return rv;
    }

    get order() { return parseInt(window.getComputedStyle(this).order); }

    set order(order) {
        if(order instanceof Date) {
            const x = order;
            const delta = Math.floor(order.seconds - Date.now() / 10);
            order = Math.floor(Math.sqrt(Math.abs(delta)) * 1.45) * Math.sign(delta) * Math.sign(parseInt(order.dir) || 1);
        }
        this.style.order = parseInt(order);
    }
}

window.customElements.define('qb-element', QBElement);

export class QBInputElement extends QBElement {
    static formAssociated = true;

    constructor() {
        super();
        this.internals = this.attachInternals();
    }

    get formData() {
        const formData = new FormData(),
            name = this.name;
        if(!this.__lookupGetter__('hash')) formData.set(name || 'value', this.value);
        else Object.entries(this.hash).forEach(([k, v]) => formData.set(name ? `${name}[${k}]` : k, v));
        return formData;
    }

    get name() { return this.getAttribute("name") }

    get readonly() { return this.hasAttribute("readonly") }
}

// get siblings() {
//     return Array.from(this.parentElement?.children || [])
// }
//
// get nthChild() {
//     return this.siblings.indexOf(this) + 1
// }
//
// get siblingsType() {
//     return this.siblings.filter(n => n.nodeName === this.nodeName)
// }
//
// get nthType() {
//     return this.siblingsType.indexOf(this) + 1
// }
//
// get siblingsKind() {
//     const m = this.nodeName.match(/^(QB-[^\-]+)-[^\-]+$/);
//     const re = m ? new RegExp(`^${m[1]}(-.+)?$`) : new RegExp(`^${this.nodeName}(-.+)?$`);
//     return this.siblings.filter(n => re.test(n.nodeName));
// }
//
// get nthKind() {
//     return this.siblingsKind.indexOf(this) + 1
// }
//
// previousElementSiblingMatch(match) {
//     let s = this.previousElementSibling
//     for (; s && !s.matches(match); s = s.previousElementSibling) {
//     }
//     return s;
// }
//
// previousElementsSiblingTill(match) {
//     let s = this.previousElementSibling;
//     const rv = [];
//     for (; s && !s.matches(match); s = s.previousElementSibling) {
//         rv.push(s)
//     }
//     return rv;
// }
//
// nextElementSiblingMatch(match) {
//     let s = this.nextElementSibling;
//     for (; s && !s.matches(match); s = s.nextElementSibling) {
//     }
//     return s;
// }
//
// nextElementsSiblingTill(match) {
//     let s = this.nextElementSibling;
//     const rv = [];
//     for (; s && !s.matches(match); s = s.nextElementSibling) {
//         rv.push(s)
//     }
//     return rv;
// }