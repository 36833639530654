/* Copyright 2022- Martin Kufner */
// const globalAttributes = "autofocus class contenteditable dir draggable enterkeyhint hidden id inert inputmode is itemid itemprop itemref itemscope lang nonce part role slot spellcheck tabindex title translate virtualkeyboardpolicy".split(/\s+/)
export const objecttype = function (object) {
    if(object === undefined) return "undefined";
    else if(object === null) return "null";
    return object.__proto__.constructor.name;
}

export class ContentTag {
    // #attributes(key, value) {
    //     if (ContentTag.globalAttributes.includes(key)) this.setAttribute(key, value);
    // }

    static schemaUrl = "https://schema.org/";

    static defaults = {
        button: {type: 'button'}
    };

    static Fragment(...args) {
        const f = document.createDocumentFragment(),
            div = document.createElement('div');
        f.append(...args.flatMap(arg => {
            if(typeof arg !== "string" || !/<\S[^>]+>/.test(arg)) return arg;
            div.innerHTML = arg;
            return [...div.childNodes];
        }));
        return f;
    }

    static update(node, ...args) {
        if(!node instanceof HTMLElement) throw 'can just update HTMLElement'
        return new this(node).update(...args);
    }

    constructor(node, ...args) {
        if(node instanceof HTMLElement) this.node = node;
        else if(node instanceof DocumentFragment) this.node = node;
        else {
            this.node = document.createElement(node);
            args.unshift(ContentTag.defaults[node.toLowerCase()]);
        }
        if(args.length) this.update(...args);
    }

    update(...args) {
        const {connect, events, ...options} = this.options(...args);
        Object.entries(options).forEach(option => this.option(...option));
        if(events?.length) events.forEach(value => this.events(value));
        if(connect) this.connect(connect)
        return options;
    }

    connect(connect) {
        let [target, method] = connect;
        if(target.nodeName === "TEMPLATE") {
            if(method === "before") method = 'prepend';
            else if(method === "after") method = 'append';
            target.content[method](this.node);
        }
        else target[method](this.node);
    }

    options(...args) {
        const options = {events: []};
        args.flat().forEach(arg => {
            if(arg === null || arg === undefined) return;
            const type = typeof arg;
            if(type !== "object") return this.node.append(arg.toString());
            switch(arg.constructor.name) {
                case 'Object':
                    const {
                        append,
                        prepend,
                        first_or_append,
                        first_or_prepend,
                        before,
                        after,
                        replace,
                        events,
                        query,
                        ...option
                    } = arg;
                    Object.assign(options, option);
                    if(events) options.events.push(events);
                    if(first_or_append) {
                        const selector = first_or_append instanceof Array ? first_or_append[0] : this.node.nodeName;
                        const node = first_or_append.querySelector(selector);
                        if(node) this.node = node;
                        else options.connect = [first_or_append instanceof Array ? first_or_append[1] : first_or_append, 'append'];
                    }
                    else if(first_or_prepend) {
                        const selector = first_or_append instanceof Array ? first_or_prepend[0] : this.node.nodeName;
                        const node = first_or_prepend.querySelector(selector);
                        if(node) this.node = node;
                        else options.connect = [first_or_prepend instanceof Array ? first_or_prepend[1] : first_or_prepend, 'prepend'];
                    }
                    else if(append) options.connect = [append, 'append'];
                    else if(prepend) options.connect = [prepend, 'prepend'];
                    else if(before) options.connect = [before, 'before'];
                    else if(after) options.connect = [after, 'after'];
                    else if(replace) options.connect = [replace, 'replace'];
                    break;
                case 'NodeList':
                    this.append(...arg);
                    break;
                default:
                    this.append(arg);
                    break;
            }
        });
        return options;
    }

    setAttribute(k, v) {
        if(v === undefined || v === null || v === false) this.node.removeAttribute(k);
        else this.node.setAttribute(k, v === undefined || v === true ? "" : v)
    }

    setAttributes(value) { Object.entries(value).forEach(v => this.setAttribute(...v)); }

    setStyles(value) {
        if(typeof value === "string" || typeof value === "number") this.node.style = value;
        else Object.assign(this.node.style, value);
    }

    styleProperties(value) {
        Object.entries(value).forEach(([k, v]) => {
            k = k.replace(/^-*/, "--");
            if(v !== undefined) this.node.style.setProperty(k, v);
            else this.node.style.removeProperty(k);
        });
    }

    append(...args) {
        if(this.node.nodeName === "TEMPLATE") this.node.content.append(...args);
        else this.node.append(...args);
    }

    cdata(value) {
        if(typeof value !== "string") value = JSON.stringify(value);
        this.append(document.createCDATASection(value));
    }

    comment(value) {
        if(typeof value !== "string") value = JSON.stringify(value);
        this.append(document.createComment(value));
    }

    option(key, value) {
        const node = this.node;
        switch(key) {
            case 'attr':
            case 'attributes':
                return this.setAttributes(value);
            case 'cdata':
                return this.cdata(value);
            case 'classList':
                return node.classList.add(...value);
            case 'comment':
                return this.comment(value);
            case 'data':
            case 'dataset':
                return Object.assign(node.dataset, Object.compact(value));
            case 'display':
                return this.setStyles({display: value ? (value === true ? 'initial' : value) : 'none'});
            case 'html':
                return node.append(ContentTag.Fragment(value));
            case 'itemid':
                return this.setAttributes({itemscope: true, itemid: value});
            case 'itemtype':
                if(!/^(https?:\/\/)/.test(value)) value = ContentTag.schemaUrl + value;
                return this.setAttributes({itemscope: true, itemtype: value});
            case 'style':
                return this.setStyles(value);
            case 'append':
            case 'before':
            case 'after':
            case 'prepend':
            case 'connect':
            case 'events':
                return;
            case "selected":
                return this.setAttributes({selected: value});
            case "styleProperties":
                return this.styleProperties(value);
            default:
                if(/^on/.test(key)) return this.option('events', [node, key, value]);
                if(/^aria-/.test(key)) return this.setAttribute(key, value);
        }

        const fn = node.__lookupSetter__(key);
        if(fn) fn.call(node, value);
        else if(!node.__lookupGetter__(key) && (key in node) && typeof node[key] === 'function') node[key].call(node, value);
        else if(/undefined|string|number|boolean/.test(typeof value)) this.setAttribute(key, value);
        else console.warn(`Could not process ${key} => `, value, node)
    }

    events(value, target) {
        if(!target) target = this.node;
        const events = [];
        switch(objecttype(value)) {
            case "Array":
                value.forEach(v => {
                    switch(objecttype(v)) {
                        case "String":
                            return events.push(...v.split(/\s+/).map(v => [v]));
                        case "Object":
                            return events.push(...Object.entries(v));
                        case "Array":
                            return events.push(v.slice(0, 2));
                        default:
                            if(typeof v === "object") target = v;
                    }
                });
                break;
            case "Object":
                const {target: _target, ...values} = value;
                if(_target) target = _target;
                events.push(...Object.entries(values))
                break;
            case "String":
                try {
                    JSON.parse(value)
                }
                catch(e) {
                    value = JSON.stringify([value]);
                }
                return this.node.setAttribute("events", value);
            case "Function":
                return this.node.events = target => this.events(value(target), target);
            default:
                return console.error(target.constructor.name, "events argument errors", value)
        }
        events.forEach(([e, fn]) => {
            e = e.replace(/^on/, '');
            if(typeof fn === "function") return this.node.addEventListener(e, fn.bind(target), {capture: true});
            const names = [`handleEvent_${e}`];
            if(fn) names.unshift(`handleEvent_${fn}_${e}`, `handleEvent_${fn}`);
            let name = this.sanitized_name;
            if(name) names.unshift(`handleEvent_${name}_${e}`, `handleEvent_${name}`);
            name = names.find(fn => fn && typeof target[fn] === 'function');
            this.node.addEventListener(e, name ? target[name].bind(target) : target, {capture: true});
            if(name) return;
            const exc = new Error(`cT Event: handler for ${e} not found: ${target.constructor.name}#${names.filter(n => n).join(`(evt) | ${target.constructor.name}#`)}(evt), fallback ${target.constructor.name}#handleEvent(evt)`);
            Object.assign(exc, {node: this.node, target});
            Reflect.defineProperty(exc, "stack", {
                value: exc.stack.replace(/\n\s+at.*?at cT[^\n]+/s, '')
            })
            console.info(exc);
        });
    }

    set eventTarget(target) {
        this.events(JSON.parse(this.node.getAttribute("events")), target);
        this.node.removeAttribute("events");
    }

    get sanitized_name() {
        return this.node.getAttribute("name")?.replace(/(-(.))|(\[)|\]/g, (...m) => m[3] ? "_" : m[2]?.toUpperCase() || "");
    }

    static utilities = {
        styleProperties: {value(node, ...args) { new ContentTag(node)['styleProperties'](...args) }}
    }
}


if(typeof location !== undefined) ContentTag.schemaUrl = location.origin + "/schema/"
