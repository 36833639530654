/* Copyright 2022- Martin Kufner */
import {QBElement, cT} from "./qb-element.js";
import {QBText} from "./qb-text.js";

class QBTextNote extends QBText {
    static observedAttributes = (()=>QBText.observedAttributes.concat(["checked", "datetime"]))();

    static styleSheetTagNames = ["qb-text"];

    // constructor() {
    //     super();
    //     // this.timeNode;
    //     // this.doneNode;
    // }

    get timeNode() {
        const value = cT.INPUT({
            type: "datetime-local",
            min: new Date().strftime("%FT%R"),
            step: 60,
            events: [this, "change"]
        });
        this.fieldset.append(cT.BUTTON({events: [this, "click"], name: "adddate", title: "Add date"}, "📆"), value);
        Object.defineProperty(this, 'timeNode', {value});
        return value;
    }

    get doneNode() {
        const value = cT.INPUT({
            append: this.fieldset,
            name: 'done',
            type: "checkbox",
            events: [this, "change"]
        });
        Object.defineProperty(this, 'doneNode', {value});
        return value;
    }

    $done(value) { this.doneNode.checked = !!value}
    get done() { return this.doneNode.checked }
    set done(value) { this.doneNode.checked = !!value }

    $datetime(value) {
        console.log("$DATETIME", value);
        this.timeNode.value = value;
    }

    set datetime(value) {
        if((this.now = value === true)) return;
        value = new Date(value);
        this.timeNode.value = value.strftime("%FT%R");
        this.timeNode.classList[value.isValid ? "add" : "remove"]("value")
    }

    get datetime() {
        let value = this.timeNode.value;
        if(!value) return;
        return `${value}:00${new Date().getTimezone()}`
    }

    set date(value) {
        if(value === true) return this.now = value === true;
        try {
            if(typeof value === "string") value = Date.utc(value);
            this.datetime = value.toISOString();
        }
        catch(e) {
            this.datetime = "";
        }
    }

    get date() {
        const date = new Date(this.timeNode.value);
        if(date.isValid) return date;
    }

    get hash() { return Object.assign(super.hash, {datetime: this.datetime, done: this.done}); }

    update(object) {
        super.update(object);
        if("datetime" in object) this.datetime = object.datetime;
        if("done" in object) this.done = object.done;
        if("date" in object) this.date = object.date;
    }

    handleEvent_change(evt) {
        this.timeNode.classList[this.timeNode.value ? "add" : "remove"]("value");
        this.changed = true;
        this.dispatch("change", this.hash);
    }

    handleEvent_input(evt) {
        super.handleEvent_input(evt);
        this.timeNode.disabled = this.currentLength === 0;
    }

    handleEvent_adddate(evt) { console.log('adddate') }

    handleEvent_focusout(evt) {
        // if (this.shadowRoot.contains(evt.relatedTarget)) return;
        if(!this.changed) return;
        const now = this.now;
        if(this.now && !this.timeNode.value) this.date = new Date();
        super.handleEvent_focusout(evt);
    }
}

window.customElements.define('qb-text-note', QBTextNote);