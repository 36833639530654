/* Copyright 2022- Martin Kufner */
import {QBElement, cT} from "./qb-element.js"
export class QBLang extends HTMLElement {
    static set(container, lang, content) {
        const node = container.querySelector(`[lang="${lang}"]`) || cT("qb-lang", {lang: lang});
        switch(content) {
            case false:
            case undefined:
            case null:
                return node?.remove();
            case true:
                content = "";
        }
        node.content = content;
        container.add(node);
    }

    set content(value) {
        this.textContent = '';
        this.append(value);
    }

    get priority() {
        if(this.lang === 'xx') return 1000;
        const prio = document.preferredLanguages.indexOf(this.lang);
        return prio === -1 ? 1001 : prio;
    }
}

export class QBMultilang extends QBElement {
    update(data) {
        switch(typeof data) {
            case "string":
            case "number":
                data = {xx: data};
            case "object": break;
            default: return;
        }
        const langs = [], remove = [], updates = {};
        Object.entries(data).forEach(([key, value]) => {
            if(key.length > 2) return updates[key] = value;
            else langs.push([key, value]);
        });
        super.update(updates);
        langs.forEach(([lang, content])=>{
            QBLang.set(this, lang, content)
        });
    }

    get nodes() { return Array.from(this.querySelectorAll("[lang]")) }

    add(node) {
        const priority = node.priority;
        if(priority === -1) return this.append(node);
        if(priority === 0) return this.prepend(node);
        const before = this.nodes.find(n=>n.priority > priority);
        if(before) before.insertAdjacentElement('beforebegin', node);
        else this.append(node);
    }
}
window.customElements.define('qb-multilang', QBMultilang);
window.customElements.define('qb-lang', QBLang);