/* Copyright 2022- Martin Kufner */
import {QBElement, cT} from "./qb-element.js"

const template = cT.Fragment(
    cT.SPAN({tabindex: 0, class: 'taborder'}),
    cT.DIV(
        cT.BUTTON({id: 'close'}),
        cT.HEADER(cT('slot', {name: "header"}, 'Overlay')),
        cT.DIV(cT('slot')),
        cT.NAV(cT('slot', {name: "nav"}))
    ),
    cT.SPAN({tabindex: 0, class: 'taborder'})
);

export class QBOverlayElement extends QBElement {
    static observedAttributes = ['open'];
    static shadow = 'open'
    static template = template;

    constructor() {
        super();
        this.closeNode = this.contentRoot.getElementById('close');
        this.contentRoot.querySelectorAll('.taborder').forEach(n => n.addEventListener("focus", (e => e.relatedTarget.focus())));
        this.focusFrom = document.activeElement;
    }

    $open(value) {
        if(value !== null) {
            document.querySelectorAll('qb-overlay').forEach(n => n !== this && (n.open = false));
            this.closeNode.focus();
        }
        else {
            this.focusFrom.focus();
            if(this.classList.contains("once")) this.remove();
        }
    }

    set open(value) {
        if(value) this.setAttribute("open", "");
        else this.removeAttribute("open");
    }

    handleEvent_close(evt) {
        const target = evt.currentTarget;
        if(target === this || target.matches("#close, .close")) this.open = false;
    }

    connectedCallback() {
        for(const node of this.parentElement.getElementsByTagName("qb-overlay")) {
            // console.log(node, this, node !== this);
            if(node !== this && !node.classList.contains("persist")) node.remove();
        }
        this.events({click: "close"});
    }
}

window.customElements.define('qb-overlay', QBOverlayElement);