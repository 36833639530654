/* Copyright 2022- Martin Kufner */

Object.defineProperties(window, {
    getFlagEmoji: {
        value: function (countryCode, replacement) {
            if (!countryCode) return replacement || "";
            const codePoints = countryCode
                .toUpperCase()
                .split('')
                .map(char => 127397 + char.charCodeAt());
            return String.fromCodePoint(...codePoints) || replacement || '';
        }
    },
    uuid: {
        get() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        }
    },
    notification: {
       value: {
           info(...text) { console.info(...text) },
           log(...text) { console.log(...text) },
           warn(...text) { console.warn(...text) },
           error(...text) { console.error(...text) }
       }
    }
});
