/* Copyright 2022- Martin Kufner */
Object.defineProperties(Function, {
    backtrace: {
        get() {
            const stack = new Error().stack.split(/\n/).slice(1)
            return stack.filter(f => !/^\s+at (eval|Function\.get|Object\.FetchExtended) /.test(f)).map(s=>s.replace(/https?:\/\/[^\/]+\/vite-dev|^\s+at |\?t=\d*/g, ''))
        }
    }
})

Object.defineProperties(Function.prototype, {
    extend: {
        get() {
            return function (...modules) {
                modules = modules.reverse();
                const properties = Object.getOwnPropertyNames(this);
                // console.log(this.name, super.name);
                if(typeof this.super === "undefined") Object.defineProperty(this, "super", {value: this.__proto__ });
                modules.forEach(module => {
                    if (module === this) return;
                    const {prototype, ...descriptors} = Object.getOwnPropertyDescriptors(module);
                    // properties.forEach(p => delete descriptors[p]);
                    console.log("extend", module.name, descriptors);
                    Object.defineProperties(this, descriptors);
                    // Object.assign(this, new module());
                    module.extended?.call(module, this);
                })

                // console.log(this.name, super.name);
                return this;
            }
        }
    },
    include: {
        value(...modules) {
            modules = modules.reverse();
            const properties = Object.getOwnPropertyNames(this.prototype);
            modules.forEach(module => {
                if (module === this) return;
                const descriptors = Object.getOwnPropertyDescriptors(module.prototype);
                // properties.forEach(p => delete descriptors[p]);
                // console.log("include", module.name, descriptors);
                Object.defineProperties(this.prototype, descriptors);
                module.included?.call(module, this);
            });
            if(typeof this.prototype.super === "undefined") Object.defineProperty(this.prototype, "super", {get() { return this.__proto__} });

            return this;
        }
    },

    mixin: {
        value(...mixins) {
            if(this.toString().startsWith("class")) return mixins.reduce((p, c)=>c(p), this);
            return mixin => [this,...mixins].reduce((p, c)=>c(p), mixin);
        }
    }
});
// test
// import "./test/function.js";
