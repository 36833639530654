import {QBInputElement, cT} from "./qb-element.js"

class QBCode extends QBInputElement {
    static nodeName = 'qb-code'
    static observedAttributes = ["size", "value"]
    static shadow = 'closed'


    get value() {
        return [...this.contentRoot.children].map(n=>n.value).join("");
    }
    set #value(value) {
        const formData = new FormData();
        formData.set(this.name, value);
        this.internals.setFormValue(formData);
    }

    set value(value) { this.$value(value)}


    $value(value) {
        value = value || '';
        this.#value = value;
        [...this.contentRoot.children].forEach((n, i) => n.value = value[i] || '');
    }

    $size(value) {
        [...this.contentRoot.children].forEach(n=>n.remove());
        for(let i=0;i<value;i++) this.contentRoot.append(
            cT.INPUT({
                type: 'text',
                autocomplete: "off",
                required: "true",
                maxLength:1,
                size: 1,
                events: [this, "input", "beforeinput"]
            })
        );
    }

    get form() { return this.internals.form }
    handleEvent_beforeinput(evt) {
        if(evt.inputType === 'insertFromPaste') {
            evt.preventDefault();
            this.setAttribute("value", evt.data.replace(/[^A-Za-z0-9]/g, ''));
            this.contentRoot.lastElementChild.focus();
            return;
        }
        if(evt.inputType === 'deleteContentBackward' && !evt.currentTarget.value) {
            evt.currentTarget.previousElementSibling?.focus();
        }

        evt.currentTarget.value = '';
    }

    handleEvent_input(evt) {
        this.#value = this.value;
        if(evt.inputType === 'deleteContentBackward') return;
        let next = evt.currentTarget.nextElementSibling;
        const elements = [...this.form.elements];
        if(!next) next = elements[elements.indexOf(this) + 1];
        next?.focus();
    }
}
customElement(QBCode);