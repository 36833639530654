if(!('clipboard' in navigator)) {
    navigator.clipboard = {
        writeText(text) {
            const node = document.createElement("pre");
            node.style = "position:fixed;top:5px;left:5px"; //width:1px;height:1px;
            node.textContent = text;
            document.body.append(node);

            const selection = getSelection(),
                oldrange = selection.rangeCount && selection.getRangeAt(0),
                range = document.createRange();
            selection.removeAllRanges();
            range.selectNodeContents(node);
            selection.addRange(range);
            document.execCommand('copy');
            node.remove();
            selection.removeAllRanges();
            if(oldrange) selection.addRange(oldrange);

        }
    }
}
Reflect.defineProperty(window, "copyText", {
    value: function(text, notify) {
        navigator.clipboard.writeText(text);
        if(notify) window.notification.info("Copy to clipboard", text)
    }
})